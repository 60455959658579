
// test

// export const baseUrl = "https://moneymailsapi.scienstechnologies.com/api/v1";
// export const REACT_APP_COOKIE_DOMAIN=".money-mails.com";
// export const Bingo_Url = "https://bingo.money-mails.com/"
// export const REACT_APP_COOKIE_SECURE=false;
// export const  Website_url = "https://admin.moneymails.scienstechnologies.com"

// stage

export const baseUrl = "https://stage-api.money-mails.com/api/v1";
export const REACT_APP_COOKIE_DOMAIN=".money-mails.com";
export const Bingo_Url = "https://stage-bingo.money-mails.com/"
export const REACT_APP_COOKIE_SECURE=false;
export const  Website_url = "https://stage-admin.money-mails.com"

// production 

// export const baseUrl = "https://api.money-mails.com/api/v1";
// export const REACT_APP_COOKIE_DOMAIN=".money-mails.com";
// export const Bingo_Url = "https://bingo.money-mails.com/"
// export const REACT_APP_COOKIE_SECURE=false;
// export const  Website_url = "https://admin.money-mails.com"


